@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");
@import "common/owl.carousel.min.css";
@import "common/magnific-popup.min.css";
@import "common/font.min.css";
@import "common/animate.min.css";
@import "common/style";
@import "common/breakpoints";

$appLogoColor : #1E1926;
$col-red: #f34f74;

.header-middle a:not(.btn):hover {
    color: #eee;
}

.header-middle .dropdown-box a:not(.btn):hover {
    color: #0d3974;
}

.form-control.is-invalid {
    border-color: #dc3545;
    margin-bottom: 0 !important;
}

.form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
}

.btn-blue {
    color: #fff;
    border-color: $logoColor;
    background-color: $logoColor;

    &:hover {
        color: #fff;
        border-color: #0e4ca0;
        background-color: #0e4ca0;
    }
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    color: #fff;
    border-color: #0e4ca0;
    background-color: #0e4ca0;
}

.btn-logout {
    background-color: transparent;
    border: none;
    font-size: 1.1em;
    line-height: 1.1;
    color: inherit;
    padding-left: 0;
}

[hidden] {
    display: none !important;
}

.shop-blade .filter-items li.active a {
    color: #336699;
}

.product-wrap .added-to-cart {
    background-color: #336699;
    border-radius: 50%;
    color: white !important;
}

.product-list .added-to-cart, .product-list .added-to-cart:hover {
    background-color: #336699;
    color: white !important;
}

.shopping-cart-form .product-name {
    min-width: 50px;
}


.header-middle {
    background-color: transparent;
    color: white;
}

.sticky-content-wrapper {
    border-bottom: 1px solid #eee;
}

.header-search.hs-expanded .select-box, .header-search.hs-expanded input.form-control {
    background-color: white;
}

.header-search .btn-search:hover, .header-search .btn-search:active, .header-search .btn-search:focus {
    background-color: white;
    color: #336699;
}

.btn-checkout.disabled {
    border-color: #eee;
    background-color: #eee;
    color: #666;
    cursor: not-allowed;
}

.cart-dropdown .products{
    max-height: 100vh;
}

.footer .widget-about .logo-footer {
    max-width: 64%;
}

.footer .widget-about {
    display: flex;
    margin-left: 5em;
    margin-top: 0;
}


.menu > li:hover > a:not(.menu-title), .menu > li.active > a:not(.menu-title) {
    color: black !important;
}

.header-bottom .main-nav a {
    color: $appLogoColor !important;
}

.vertical-menu > li:hover    {
    background-color: $appLogoColor;
}

.category-menu li:hover > a:not(.menu-title) {
    color: white !important;
    border-bottom: none;
}

.product .product-price, .product-name, .product-single .product-title, .shop-table td.product-price span:not(.amount), .shop-table td.product-name a, .order-success, .title-link-wrapper .title {
    color: $appLogoColor;
}

.product {

    .product-action {
        position: relative;
        visibility: visible;
        opacity: 1;
        padding: 0;
    }

    .btn-product-icon {
        margin-bottom: 0;
    }

    .btn-product {
        padding: .5em 0;
    }

    .btn-product-icon {
        border-color: transparent;
        color: #fff;
        background-color: transparent;

        &:hover {
            color: #999;
        }
    }

}

.product:not(.product-single):hover .product-countdown-container {
    visibility: visible;
    opacity: 1;
}

.product-single .n-btn-cart {
    flex: 1;
    margin-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
    min-width: 14rem;
}

.product-single .n-btn-cart {
    border-radius: 3px;
}

.cart-toggle.label-down.link {
    color: white;
}

.footer, .header {
    //background-color: $appLogoColor !important;
    background: rgb(13,57,116);
    background: linear-gradient(90deg, rgba(13,57,116,1) 17%, rgba(15,115,252,1) 85%);
}

.footer a {
    color: #ffffffa8;
}

.footer a:hover, .footer a:active, .footer a.active {
    color: #ffffffe0;
}

.footer-top .widget-title {
    color: white !important;
}


.footer-bottom {
    color: #ffffffbf;
}


.shop-gallery {

    .category-title {
        margin: 1.5em 0;
        border-bottom: 1px solid #eee;
    }
}

.shop-product {

    .fix-bottom.product-sticky-content.fixed .btn-wish {
        margin-bottom: 0;
    }

    .btn-wish {
        background: none;
        padding: 0;
        border: none;
        margin-bottom: .5em;
        margin-left: 1em;

        &:hover {
            border-radius: 3px !important;
        }

        a {
            padding: .9em .8em .7em !important;
            margin-bottom: 0;
        }
    }
}

.btn-product-icon.w-icon-cart.added {
    color: $col-red;
}

.shop-table td.product-name {
    min-width: 200px;
}

.no-available {
    font-size: 1.5em;
    margin-bottom: 1.5em;
    color: $col-red;
}

.empty-content {
    margin: 6em;

    p:first-child {
        border-bottom: 1px solid #8080807a !important;
        padding-bottom: 1.5em;
        padding-left: 1em;
    }

    p:last-child {
        text-align: end;
    }
}

.header-middle .logo {
    max-width: 18%;
    margin-left: 6em !important;

    img {
        width: 100%;
    }
}

.frequently-bought-together.promotion {

    .product-media .label-discount {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.title-link-wrapper .product-countdown-container {
    display: flex;
    max-width: -webkit-max-content;
    max-width: max-content;
    margin: 0 0 0.5rem 2.2rem;
    padding: 0.3rem 0.9rem;
    border-radius: 3px;
    background-color: #f7f7f7;
}

.no-available + form button {
    position: relative;
    bottom: 8px;
}

.product-form .no-available {
    flex-grow: 1;
}

.product-lg {
    padding: 2.4rem 2rem 1.2rem;
    border: 1px solid #26c;

    .owl-nav-top .owl-nav {
        top: -4.8rem;
    }

    .owl-nav-top>.owl-nav {
        position: absolute;
        top: -5.3rem;
        right: -.4rem;
        color: #777;
    }

    .product-details {
        padding-bottom: 1.4rem;
    }

    .product-name {
        margin-bottom: .6rem;
        font-size: 1.5rem;
    }

    .product-price {
        font-size: 1.8rem;
    }

    .product.product-promo {

        .product-countdown-container {
            position: relative !important;
            background-color: transparent;
        }

        &:hover .product-countdown-container {
            opacity: 1;
            visibility: visible;
        }
    }

    .product-countdown-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
    }

    .product-countdown-container h4 {
        margin-bottom: 0;
    }

    .product-countdown-container label {
        font-size: 1.3rem;
        letter-spacing: .015em;
    }

    .product-countdown {
        flex: 1;
        margin: 0 1rem;
        border-radius: .3rem;
        background-color: #4e4e4e;
        color: #fff;
    }

    .countdown-row {
        display: flex;
        padding: 0 1.8rem;
    }

    .countdown-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.85rem .5rem;
    }

    .countdown-amount {
        font-weight: 700;
        font-size: 1.51rem;
        line-height: 1.4;
    }

    .countdown-period {
        font-size: 1.078rem;
        letter-spacing: .025em;
    }

    .countdown-periode {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
    }

    .countdown-periode-date {
        flex: 1;
        display: flex;
        color: black;
        align-items: center;
        font-size: 1.5em;
        margin-left: -1em;

        span {
            background-color: #4e4e4e;
            color: #fff;
            flex: 1;
            white-space: pre-wrap;
            padding: .5em;
            margin: 0 .3em;
            font-size: 1em;
            border-radius: 0.3rem;

            &:first-child {
                margin-right: .5em;
            }

            &:last-child {
                margin-left: .5em;
            }

        }
    }
}

.br-sm {
    border-radius: .5rem !important;
    overflow: hidden !important;
}

.owl-theme .owl-nav .owl-next {
    right: -6px !important;
}

.product-gallery-sticky.without-thumb {

    .product-single-carousel {
        max-width: 100% !important;
    }

}

.invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: rgb(220, 53, 69);
    display: block;
    margin-bottom: 1.6rem;
}

.page-counter-down {

    margin: 1em 0;

    &.product:hover .product-countdown-container {
        opacity: 1  !important;
        visibility: visible !important;
    }

    .product-countdown-container {
        font-size: 1.6em;
        position: relative !important;
        margin: 1em 0 !important;
        left: 0 !important;
        right: 0 !important;
        background-color: #dd4b39 !important;
    }


}
.text-danger {
    color: #dd4b39 !important;
}

.offer-gallery {
    display: none;
}

.mfp-bg {
    background: #0b0b0b;
    opacity: .8;
}

.mfp-bottom-bar {
    margin-top: 10px;
    padding-bottom: 20px;
}

.mfp-image-holder .mfp-close {
    width: 18px;
}

img.mfp-img {
    padding: 0 !important;
}

.mfp-title a {
    color: #F3F3F3;

    &:hover {
        color: #aaa;
    }
}

.header-bottom .header-right > a:hover {
    color: #333;
}

.owl-nav-md > .owl-nav .owl-next, .owl-nav-md > .owl-nav .owl-prev {
    color: red;
}

.find-order-container {
    max-width: 600px;
    margin: auto;
    padding: 3em;
}

.full-page-loader {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0,0,0,.8);

    .page-loader-message {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        flex-direction: column;

        p {
            font-size: 1.2em;
            margin-bottom: .5em;
        }
    }

    .lds-grid {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-grid div {
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #3b8dfe;
        animation: lds-grid 1.2s linear infinite;
    }
    .lds-grid div:nth-child(1) {
        top: 8px;
        left: 8px;
        animation-delay: 0s;
    }
    .lds-grid div:nth-child(2) {
        top: 8px;
        left: 32px;
        animation-delay: -0.4s;
    }
    .lds-grid div:nth-child(3) {
        top: 8px;
        left: 56px;
        animation-delay: -0.8s;
    }
    .lds-grid div:nth-child(4) {
        top: 32px;
        left: 8px;
        animation-delay: -0.4s;
    }
    .lds-grid div:nth-child(5) {
        top: 32px;
        left: 32px;
        animation-delay: -0.8s;
    }
    .lds-grid div:nth-child(6) {
        top: 32px;
        left: 56px;
        animation-delay: -1.2s;
    }
    .lds-grid div:nth-child(7) {
        top: 56px;
        left: 8px;
        animation-delay: -0.8s;
    }
    .lds-grid div:nth-child(8) {
        top: 56px;
        left: 32px;
        animation-delay: -1.2s;
    }
    .lds-grid div:nth-child(9) {
        top: 56px;
        left: 56px;
        animation-delay: -1.6s;
    }
    @keyframes lds-grid {
        0%, 100% {
            opacity: 1;
        }
        50% {
            opacity: 0.5;
        }
    }
}

iframe#kkiapay_iframe .payment-widget .close {
    display: none;
}


.order-table .product-name {
    white-space: break-spaces;
}

.text-danger {
    color: #ff4747 !important;
}

.text-success {
    color: #71c016 !important;
}

.product-availability {
    font-size: 1.1em;
}

.product-single .product-short-desc, .product-tab-description {
    white-space: pre-line;
    word-break: break-all;
}

.frequently-bought-together-bonus {

    align-items: start;

    .title {
        font-size: initial;
        margin-right: 2em;
        margin-top: 1em;
        white-space: nowrap;
    }

    .frequently-bought-together-description {
        white-space: pre-line;
        word-break: break-all;
    }
}


.product-price {

    .old-price {
        order: 1;
        font-size: .9em;
    }

    .new-price {
        white-space: normal;

        &:not(:last-child) {
            order: 2;
        }
    }

}

.product:not(.product-single) .product-price {
    flex-direction: column;
    align-items: center;
    display: flex;
}

@media screen and (min-width: 1024px) and (max-width: 1440px){
    img.mfp-img {
        max-height: 600px !important;
    }
}

@media screen and (max-width: 768px){
    .product .product-price {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .product-price .new-price {
        order: 2;
    }

    .product-price .old-price {
        order: 1;
    }

    .product-lg .product-price {
        display: block !important;
    }

    #account-orders.tab-pane .icon-box-side {
        flex-direction: row;
    }
}

